<template>
  <validation-observer tag="form" v-slot="validationObserver">
    <product-form-fields
      :product="product"
      :validationObserver="validationObserver"
    ></product-form-fields>
  </validation-observer>
</template>

<script>
import ProductFormFields from "./ProductFormFields.vue";

export default {
  name: "EditProduct",
  components: {
    ProductFormFields,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
};
</script>
