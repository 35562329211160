<template>
  <v-dialog v-model="dialog" class="product-update" fullscreen>
    <v-card>
      <v-toolbar flat class="toolbar">
        <v-icon color="primary" @click="close()" class="mr-4"
          >mdi-chevron-left</v-icon
        >
        <v-toolbar-title
          class="primary--text font-weight-bold font-size-normal"
        >
          Rediger produkt</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            depressed
            outlined
            color="primary"
            class="white-bg rounded-pill mr-3"
            @click="close()"
          >
            <span class="mr-2">Avbryt</span>
            <v-icon small color="primary">mdi-close</v-icon>
          </v-btn>
          <product-form-save-btn
            @product:saved="close()"
          ></product-form-save-btn>
        </v-card-actions>
      </v-toolbar>
      <div class="dialog-content">
        <template v-if="loadingProduct">
          <progress-bouncing class="mt-5" center></progress-bouncing>
        </template>
        <template v-else>
          <v-container class="py-0">
            <brand-serie-product-nav
              class="my-8"
              v-if="product"
              v-bind="breadcrumbs"
            ></brand-serie-product-nav>
            <product-form :product="product"></product-form>
          </v-container>
        </template>
      </div>
    </v-card>
    <v-overlay :value="isSaving" color="black">
      <progress-bouncing center></progress-bouncing> Lagrer produkt
    </v-overlay>
  </v-dialog>
</template>

<script>
import ProductForm from "./components/edit/ProductForm.vue";
import ProductFormSaveBtn from "./components/edit/ProductFormSaveBtn.vue";
import BrandSerieProductNav from "@/components/BrandSerieProductNav.vue";
export default {
  name: "ProductUpdate",
  components: { ProductForm, BrandSerieProductNav, ProductFormSaveBtn },
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      loadingProduct: false,
      dialog: true,
    };
  },
  computed: {
    product() {
      return this.$store.getters["products/getProductById"](this.id);
    },
    breadcrumbs() {
      return {
        brand: { name: this.product.brandName, id: this.product.brandId },
        serie: {
          name: this.product.productSeriesName,
          id: this.product.productSeriesId,
        },
        product: { name: this.product.productName, id: this.product.id },
      };
    },
    isSaving() {
      return this.$store.state["product-form"].isSaving;
    },
  },
  methods: {
    fetchProduct() {
      try {
        this.loadingProduct = true;
        this.$store.dispatch("products/fetchProductById", this.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingProduct = false;
      }
    },
    close() {
      this.$router.push({ name: "Product", params: { id: this.id } });
    },
  },
  created() {
    this.fetchProduct();
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: linear-gradient(90deg, #bfe5e8, #efddd1);
}
.dialog-content {
  padding-top: 64px;
}

.font-size-normal {
  font-size: $font-size-root;
}
.white-bg {
  background: white;
}
.container {
  max-width: 1170px;
}
</style>