const productNameRegex =
  /^[-0-9a-zA-ZæøåÆØÅ+=!?#%&()\*,\.\/:@|""'´`°ÁÀÄÉÈÍÌºÓÒÖáàäéèíìóòöüú\s<>®_’¡™]*$/;
export default {
  dataProviderProductId: {
    required: true,
    max: { length: 50 },
    regex: /^[-0-9a-zA-Z&+’\.\-_\,\/?]+$/,
  },
  brandId: {
    required: true,
  },
  productSeriesId: {
    required: true,
  },
  productName: {
    required: true,
    max: { length: 128 },
    regex: productNameRegex,
  },
  productShortName: {
    required: true,
    max: { length: 30 },
    regex: productNameRegex,
  },
  description: {
    max: { length: 8000 },
  },
  activeStatus: {
    required: true,
  },
  isSalesProduct: {
    required: true,
  },
  inPriceExVat: {
    min_value: { min: 0 },
  },
  recommendedSalesPriceIncVat: {
    min_value: { min: 0 },
  },
  vat: {
    min_value: { min: 0 },
    max_value: { max: 99.9 },
  },
  productCategoryId: {
    required: true,
  },
  size: {
    double: {},
    min_value: { min: 0 },
  },
  unit: {
    regex: /^[a-zA-Z-/]*$/,
  },
  packageSize: {
    integer: true,
    min_value: { min: 1 },
  },
  minimumOrderQuantity: {
    integer: true,
    min_value: { min: 1 },
  },
  supplementaryNumber: {
    required: true,
    max: { length: 50 },
    regex: /^[-0-9a-zA-Z&+\.\-_\,\/?]+$/,
  },
  ean: {
    max: { length: 20 },
    numeric: true,
    uniqueEan: true,
  },
  shelfDescription: {
    max: { length: 150 },
    regex:
      /^[-0-9a-zA-ZæøåÆØÅ+=!?#%&()\*,\.\/:@|""'´°ÁÀÄÉÈÍÌºÓÒÖáàäéèíìóòöü\s<>®]*$/,
  },
  productType: {
    max: { length: 30 },
    regex:
      /^[-0-9a-zA-ZæøåÆØÅ+=!?#%&()\*,\.\/:@|""'´°ÁÀÄÉÈÍÌºÓÒÖáàäéèíìóòöü\s<>®]*$/,
  },

  color: {
    max: { length: 50 },
    regex:
      /^[-0-9a-zA-ZæøåÆØÅ+=!?#%&()\*,\.\/:@|""'´°ÁÀÄÉÈÍÌºÓÒÖáàäéèíìóòöü\s<>®]*$/,
  },
  colorCode: {
    max: { length: 50 },
    regex: /^[-0-9a-zA-Z#%*,\.\/+]*$/,
  },
  priceChangeFromDate: {},
  videoUrl: {
    max: { length: 500 },
  },
  hasTester: {},
  campaignFromDate: {},
  campaignToDate: {},
};
