<template>
  <v-container v-if="isDev">
    <div class="d-flex align-start">
      <v-textarea
        class="mr-5"
        filled
        v-model="jsonOrder"
        rows="10"
        placeholder="Click on an order to fill with JSON data"
      ></v-textarea>
      <pre
        style="
          width: 400px;
          max-height: 280px;
          overflow-y: auto;
          overflow-x: auto;
          position: relative;
        "
      >
        JSON data
      <code v-if="selectedOrder">{{ selectedOrder }} </code>
    </pre>
    </div>

    <v-checkbox
      v-model="keepMissingOrderLines"
      label="KeepMissingOrderLines"
    ></v-checkbox>
    <v-btn
      color="primary"
      depressed
      tile
      @click="updateOrder()"
      :disabled="!jsonOrder"
    >
      TEST API Update order
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="orders"
      :options="{ itemsPerPage: -1 }"
      @click:row="setSelectedOrder($event)"
    ></v-data-table>
  </v-container>
</template>
<script>
import axios from "axios";

const supplierOrderEndpoint = `${process.env.VUE_APP_API_URL}/api/orders`;
const productCatalogOrderEndpoint = `http://localhost:13906/api/orders`;

const toSupplierApiOrderItem = (orderItem) => {
  return {
    orderItemId: orderItem.orderItemId,
    dataProviderProductId: orderItem.dataProviderProductId,
    confirmedAmount: orderItem.confirmedAmount,
    confirmedActualPricePerUnitExlVat:
      orderItem.confirmedActualPricePerUnitExlVat,
  };
};

const toSupplierApiOrder = (orderDetails) => {
  return {
    orderId: orderDetails.orderId,
    supplierId: orderDetails.supplierId,
    siteId: orderDetails.siteId,
    orderType: orderDetails.orderType,
    currentStatus: orderDetails.currentStatus,
    trackingUrl: orderDetails.trackingUrl,
    orderItemList: orderDetails.orderItemList.map(toSupplierApiOrderItem),
  };
};

export default {
  data() {
    return {
      isDev: /^(http:\/\/|https:\/\/)localhost\b/.test(window.location.href),
      orders: [],
      selectedOrder: null,
      jsonOrder: "",
      keepMissingOrderLines: true,
    };
  },
  computed: {
    headers() {
      if (this.orders.length === 0) return [];
      return Object.keys(this.orders[0]).map((key) => ({
        text: key,
        value: key,
      }));
    },
  },
  methods: {
    updateOrder() {
      if (this.jsonOrder) {
        const order = JSON.parse(this.jsonOrder);
        axios.put(
          `${supplierOrderEndpoint}/${this.selectedOrder.orderId}?keepMissingOrderLines=${this.keepMissingOrderLines}`,
          order
        );
      }
    },
    async setSelectedOrder(order) {
      this.selectedOrder = null;
      const {
        data: { data: orderDetails },
      } = await axios.get(`${productCatalogOrderEndpoint}/${order.orderId}`);
      this.selectedOrder = toSupplierApiOrder(orderDetails);
      this.jsonOrder = JSON.stringify(this.selectedOrder);
    },
  },
  async created() {
    const {
      data: {
        data: { items },
      },
    } = await axios.get(productCatalogOrderEndpoint);
    this.orders = items;
  },
};
</script>
