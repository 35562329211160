<template>
  <v-menu
    z-index="99999"
    ref="menu"
    :close-on-content-click="false"
    v-model="menu"
    :nudge-right="0"
    :nudge-bottom="50"
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
    internal-activator
    v-bind="menuOptions"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :class="textFieldClass"
        v-model="textFieldDate"
        :label="label"
        :placeholder="placeholder"
        append-icon="mdi-calendar"
        :clearable="clearable"
        :error-messages="errorMessages"
        readonly
        :disabled="readonly"
        :hide-details="hideDetails"
        color="primary"
        v-on="on"
        @click:append="menu = true"
        v-bind="textFieldOptions"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="datePickerDate"
      no-title
      @input="menu = false"
      :show-current="true"
      :min="min"
      :max="max"
      locale="nb-no"
      :readonly="readonly"
      :first-day-of-week="firstDayOfWeek"
      color="primary"
      :day-format="dayFormat"
      :weekday-format="weekdayFormat"
      v-bind="datePickerOptions"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";

export default {
  model: {
    prop: "date",
    event: "input",
  },
  props: {
    date: {},
    label: {},
    placeholder: {},
    min: {},
    max: {},
    errorMessages: {},
    readonly: {},
    clearable: {},
    "hide-details": {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "DD.MM.YYYY",
    },
    firstDayOfWeek: {
      type: Number,
      default: 1,
    },
    todayText: {
      type: String,
      default: "I dag",
    },
    yesterdayText: {
      type: String,
      default: "I går",
    },
    tommorowText: {
      type: String,
      default: "I morgen",
    },
    relativeDates: {
      type: Boolean,
      default: false,
    },
    textFieldClass: {
      type: String,
      default: "",
    },
    datePickerOptions: {
      type: Object,
      default: undefined,
    },
    textFieldOptions: {
      type: Object,
      default: undefined,
    },
    menuOptions: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      menu: false,
      attach: null,
    };
  },
  computed: {
    // Text-field (Only responsible for displaying the formatted date)
    textFieldDate: {
      get() {
        if (this.relativeDates) {
          return this.getRelativeDateName();
        } else {
          return this.date ? moment(this.date).format(this.format) : "";
        }
      },
      set(value) {
        this.datePickerDate = undefined; // Set is only used when clearing text-field.
      },
    },
    // Date-picker (Responsible for displaying the date in the picker and emitting the selected value)
    datePickerDate: {
      get() {
        return this.date ? moment(this.date).format("YYYY-MM-DD") : "";
      },
      set(datePickerDate) {
        let val = datePickerDate ? moment(datePickerDate).toDate() : null; // Allowing to reset the date to null
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getRelativeDateName() {
      if (!this.date) return "";
      let today = moment().startOf("day");
      let date = moment(this.date);
      let diff = date.diff(today, "days");
      if (diff === -1) return this.yesterdayText;
      else if (diff === 0) return this.todayText;
      else if (diff === 1) return this.tommorowText;
      else return moment(this.date).format(this.format);
    },
    dayFormat(dateStr) {
      return moment(dateStr).format("D");
    },
    weekdayFormat(dateStr) {
      return moment(dateStr).format("dd.");
    },
  },
};
</script>
