<template>
  <v-text-field
    v-model="textInput"
    class="percentage"
    v-bind="$attrs"
    @focus="onFocus"
    @blur="onBlur"
  ></v-text-field>
</template>

<script>
import round from "lodash.round";

function toNumber(
  value,
  decimalPlaces = null,
  separator = ",",
  defaultValue = 0
) {
  /**
    - Make value a string, 
    - Replace all non-numeric chars  
    - Make sure the separator is a dot (.)
    */
  const str =
    value != null
      ? value
          .toString()
          .replace(/[^0-9.,-]+/g, "")
          .replaceAll(separator, ".")
      : defaultValue;

  // Parse string to number
  const parsedFloat = parseFloat(str);

  // Not a number
  if (isNaN(parsedFloat)) {
    return defaultValue;
  }

  // Round to nearest decimal if decimalPlaces are specified
  if (decimalPlaces != null) {
    return round(parsedFloat, decimalPlaces);
  }
  return parsedFloat;
}

function formatNumber(n, decimalPlaces = null, separator = ",") {
  if (decimalPlaces != null) {
    return n.toFixed(decimalPlaces).replace(".", separator);
  } else {
    return n.toString().replace(".", separator);
  }
}

function toDisplayString(
  n,
  decimalPlaces = null,
  separator = ",",
  prefix = "",
  suffix = ""
) {
  if (typeof n === "number") {
    return `${prefix}${formatNumber(n, decimalPlaces, separator)}${suffix}`;
  }
  return "";
}

function toInputString(value, decimalPlaces = null, separator = ",") {
  const n = toNumber(value);
  return formatNumber(n, decimalPlaces, separator);
}

export default {
  name: "TextFieldDecimal",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Number],
    },
    /**The count of decimal places. */
    decimals: {
      required: false,
      type: [Number],
      default: null,
    },
    /**The separator (. or ,). Default comma. */
    separator: {
      required: false,
      type: [String],
      default: ",",
      validator: (value) => [".", ","].includes(value),
    },
    prefix: {
      required: false,
      type: [String],
      default: "",
    },
    suffix: {
      required: false,
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      textInput: this.toDisplayString(this.value),
    };
  },
  watch: {
    value(newValue) {
      this.textInput = this.toDisplayString(newValue);
    },
  },
  methods: {
    toDisplayString(value) {
      return toDisplayString(
        this.value,
        this.decimals,
        this.separator,
        this.prefix,
        this.suffix
      );
    },
    onFocus() {
      this.textInput = toInputString(
        this.textInput,
        this.decimals,
        this.separator
      );
    },
    onBlur() {
      const number = toNumber(this.textInput, this.decimals);
      this.$emit("change", number);
      this.textInput = this.toDisplayString(number);
    },
  },
};
</script>
