<template>
  <div class="d-flex align-center mb-8">
    <div class="tag-circle mr-1" :style="{ background: tagColor }">
      <div class="tag">
        {{ tag }}
      </div>
    </div>
    <span class="tag-text">{{ text }}</span>
  </div>
</template>

<script>
const fixitYellow = "#fce583";
const fixitPeach = "#efddd1";

export default {
  name: "ArticleType",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tag() {
      return this.product.isSalesProduct ? "S" : "F";
    },
    tagColor() {
      return this.product.isSalesProduct ? fixitYellow : fixitPeach;
    },
    text() {
      return this.product.isSalesProduct ? "Salgsvare" : "Forbruksvare";
    },
  },
};
</script>

<style scoped>
.tag-circle {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.tag {
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
}

.tag-text {
  font-size: 13px;
}
</style>