<template>
  <div class="mb-8">
    <h3>Beskrivelse</h3>
    <span v-if="product.description"> {{ product.description }}</span>
    <span v-else class="text--disabled"> Ingen beskrivelse</span>
  </div>
</template>

<script>
export default {
  name: "ProductDescription",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>