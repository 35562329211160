<template>
  <section class="product-details">
    <v-container>
      <template v-if="product">
        <brand-serie-product-nav v-bind="breadcrumbs"></brand-serie-product-nav>
        <v-row class="mt-5">
          <v-col v-if="$vuetify.breakpoint.xsOnly">
            <div class="d-flex align-center justify-space-between">
              <product-name :product="product" class="mr-2"></product-name>
              <edit-product-btn v-if="adminMode"
                @click="editProduct()"
                class="mb-5"
              ></edit-product-btn>
            </div>
            <brand-serie-name :product="product"></brand-serie-name>
          </v-col>

          <v-col cols="12" sm="4">
            <product-image  
              :adminMode="adminMode"
              :src="product.imageUrl"
              :imageId="product.id"
            ></product-image>
          </v-col>

          <v-col cols="12" sm="8">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <brand-serie-name :product="product"></brand-serie-name>
              <div class="d-flex align-center justify-space-between">
                <product-name :product="product" class="mr-2"></product-name>
                <edit-product-btn v-if="adminMode"
                  @click="editProduct()"
                  class="mb-5"
                ></edit-product-btn>
              </div>
            </template>

            <article-type :product="product"></article-type>
            <product-prices :product="product"></product-prices>
            <product-description :product="product"></product-description>
            <product-details-section
              :product="product"
            ></product-details-section>
          </v-col>
        </v-row>
      </template>
      <template v-if="showProductLoader">
        <progress-bouncing center></progress-bouncing>
      </template>
    </v-container>
  </section>
</template>

<script>
import BrandSerieProductNav from "@/components/BrandSerieProductNav.vue";
import ProgressBouncing from "@fixit/progress-bouncing";
import ArticleType from "./components/read/ArticleType.vue";
import BrandSerieName from "./components/read/BrandSerieName.vue";
import ProductPrices from "./components/read/ProductPrices.vue";
import ProductImage from "./components/read/ProductImage.vue";
import ProductName from "./components/read/ProductName.vue";
import ProductDescription from "./components/read/ProductDescription.vue";
import ProductDetailsSection from "./components/read/ProductDetailsSection.vue";
import EditProductBtn from "./components/read/EditProductBtn.vue";

export default {
  name: "ProductDetails",
  components: {
    BrandSerieProductNav,
    ProgressBouncing,
    ArticleType,
    BrandSerieName,
    ProductPrices,
    ProductImage,
    ProductName,
    ProductDescription,
    ProductDetailsSection,
    EditProductBtn,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      loadingProduct: false,
    };
  },
  computed: {
    breadcrumbs() {
      return {
        brand: { name: this.product.brandName, id: this.product.brandId },
        serie: {
          name: this.product.productSeriesName,
          id: this.product.productSeriesId,
        },
        product: { name: this.product.productName, id: this.product.id },
      };
    },
    product() {
      return this.$store.getters["products/getProductById"](this.id);
    },

    showProductLoader() {
      return this.product == null && this.loadingProduct;
    },
  },
  methods: {
    fetchProduct() {
      try {
        this.loadingProduct = true;
        this.$store.dispatch("products/fetchProductById", this.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingProduct = false;
      }
    },
    editProduct() {
      this.$router.push({ name: "ProductUpdate", params: { id: this.id } });
    },
  },
  created() {
    this.fetchProduct();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.product-details {
  text-align: left;
  color: var(--v-primary-base);
  .container {
    max-width: 1170px;
  }
}
</style>