<template>
  <v-row class="edit-product pb-5">
    <v-col cols="12" md="4" class="py-0">
      <product-image
        :src="imageUrl"
        :imageId="id"
        max-width="400"
        @image:uploaded="setImage($event)"
      ></product-image>
    </v-col>
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12" class="py-0">
          <label>Produkt-ID</label>
          <validation-provider
            name="dataProviderProductId"
            :rules="rules['dataProviderProductId']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="dataProviderProductId"
              v-bind="inputOptions"
              :disabled="id > 0"
              :error-messages="dataProviderProductIdServerErrors.concat(errors)"
              :counter="rules['dataProviderProductId'].max.length"
              :maxlength="rules['dataProviderProductId'].max.length"
              @blur="validateDataProviderProductId(dataProviderProductId)"
              :loading="dataProviderProductIdServerValidationPending"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label>Merke</label>
          <validation-provider
            name="brandId"
            :rules="rules['brandId']"
            v-slot="{ errors }"
          >
            <v-select
              v-model="brandId"
              :items="brands"
              item-value="id"
              item-text="name"
              v-bind="inputOptions"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label>Serie</label>
          <validation-provider
            name="productSeriesId"
            :rules="rules['productSeriesId']"
            v-slot="{ errors }"
          >
            <v-select
              v-model="productSeriesId"
              :items="seriesInBrand"
              item-value="id"
              item-text="name"
              v-bind="inputOptions"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="py-0">
          <label>Produktnavn</label>
          <validation-provider
            name="productName"
            :rules="rules['productName']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="productName"
              v-bind="inputOptions"
              :error-messages="errors"
              :counter="rules['productName'].max.length"
              :maxlength="rules['productName'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" class="py-0">
          <label>Kort navn i kassen (maks 30 tegn)</label>
          <validation-provider
            name="productShortName"
            :rules="rules['productShortName']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="productShortName"
              v-bind="inputOptions"
              :error-messages="errors"
              :counter="rules['productShortName'].max.length"
              :maxlength="rules['productShortName'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Beskrivelse</label>
          <validation-provider
            name="description"
            :rules="rules['description']"
            v-slot="{ errors }"
          >
            <v-textarea
              v-model.trim="description"
              v-bind="inputOptions"
              :error-messages="errors"
              :counter="rules['description'].max.length"
              :maxlength="rules['description'].max.length"
            ></v-textarea>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Type vare</label>
          <validation-provider
            name="isSalesProduct"
            :rules="rules['isSalesProduct']"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="isSalesProduct"
              row
              class="mt-0"
              :error-messages="errors"
            >
              <v-radio
                label="Salgsvare"
                :value="true"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
              <v-radio
                label="Forbruksvare"
                :value="false"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
            </v-radio-group>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label>Veil. innpris (ekskl. mva)</label>
          <validation-provider
            name="inPriceExVat"
            :rules="rules['inPriceExVat']"
            v-slot="{ errors }"
          >
            <text-field-currency
              v-model="inPriceExVat"
              v-bind="inputOptions"
              :error-messages="errors"
            ></text-field-currency>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label>Anbefalt utpris (inkl. mva)</label>
          <validation-provider
            name="recommendedSalesPriceIncVat"
            :rules="rules['recommendedSalesPriceIncVat']"
            v-slot="{ errors }"
          >
            <text-field-currency
              v-model="recommendedSalesPriceIncVat"
              v-bind="inputOptions"
              :error-messages="errors"
            ></text-field-currency>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Fra hvilken dato den anbefalte prisen bør brukes</label>
          <validation-provider
            name="priceChangeFromDate"
            :rules="rules['priceChangeFromDate']"
            v-slot="{ errors }"
          >
            <date-picker
              v-model="priceChangeFromDate"
              :textFieldOptions="inputOptions"
              :error-messages="errors"
              clearable
            ></date-picker>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Tilgjengelighet</label>
          <validation-provider
            name="activeStatus"
            :rules="rules['activeStatus']"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="activeStatus"
              row
              class="mt-0"
              :error-messages="errors"
            >
              <v-radio
                label="Aktiv"
                :value="true"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
              <v-radio
                label="Inaktiv"
                :value="false"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
            </v-radio-group>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <text-field-barcode
            v-model="ean"
            v-bind="inputOptions"
            :barcodes="barcodes"
            :loading="deletingBarcode || savingBarcode"
            :readonly="savingBarcode"
            :dialog="deleteBarcodeDialog"
            @dialog:change="deleteBarcodeDialog = $event"
            @barcode:delete="deleteBarcode($event)"
            @barcode:add="addBarcode($event)"
            :multi="id > 0"
          ></text-field-barcode>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Mva-sats (%)</label>
          <validation-provider
            name="vat"
            :rules="rules['vat']"
            v-slot="{ errors }"
          >
            <text-field-decimal
              v-model="vat"
              v-bind="inputOptions"
              :error-messages="errors"
              suffix="%"
            ></text-field-decimal>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Kategori</label>
          <validation-provider
            name="productCategoryId"
            :rules="rules['productCategoryId']"
            v-slot="{ errors }"
          >
            <v-select
              v-model="productCategoryId"
              :items="categories"
              v-bind="inputOptions"
              item-text="name"
              item-value="id"
              :error-messages="errors"
            ></v-select>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="3" class="py-0">
          <label>Størrelse</label>
          <validation-provider
            name="size"
            :rules="rules['size']"
            v-slot="{ errors }"
          >
            <text-field-decimal
              v-model="size"
              v-bind="inputOptions"
              :error-messages="errors"
            ></text-field-decimal>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="3" class="py-0">
          <label>Enhet</label>
          <validation-provider
            name="unit"
            :rules="rules['unit']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="unit"
              v-bind="inputOptions"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="3" class="py-0">
          <label>Enheter per pakke</label>
          <validation-provider
            name="packageSize"
            :rules="rules['packageSize']"
            v-slot="{ errors }"
          >
            <v-text-field
              type="number"
              v-model="packageSize"
              v-bind="inputOptions"
              :min="rules['packageSize'].min_value.min"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="3" class="py-0">
          <label>Minimum ordreantall</label>
          <validation-provider
            name="minimumOrderQuantity"
            :rules="rules['minimumOrderQuantity']"
            v-slot="{ errors }"
          >
            <v-text-field
              type="number"
              v-model="minimumOrderQuantity"
              v-bind="inputOptions"
              :min="rules['minimumOrderQuantity'].min_value.min"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Leverandørens artikkelnummer</label>
          <validation-provider
            name="supplementaryNumber"
            :rules="rules['supplementaryNumber']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="supplementaryNumber"
              v-bind="inputOptions"
              :error-messages="supplementaryNumberServerErrors.concat(errors)"
              :maxlength="rules['supplementaryNumber'].max.length"
              :counter="rules['supplementaryNumber'].max.length"
              @blur="validateSupplementaryNumber(supplementaryNumber)"
              :loading="supplementaryNumberServerValidationPending"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Hyllekanttekst</label>
          <validation-provider
            name="shelfDescription"
            :rules="rules['shelfDescription']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="shelfDescription"
              v-bind="inputOptions"
              :error-messages="errors"
              :maxlength="rules['shelfDescription'].max.length"
              :counter="rules['shelfDescription'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Produkttype</label>
          <validation-provider
            name="productType"
            :rules="rules['productType']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="productType"
              v-bind="inputOptions"
              :error-messages="errors"
              :maxlength="rules['productType'].max.length"
              :counter="rules['productType'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <label>Farge</label>
          <validation-provider
            name="color"
            :rules="rules['color']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="color"
              v-bind="inputOptions"
              :error-messages="errors"
              :maxlength="rules['color'].max.length"
              :counter="rules['color'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <label>Fargekode</label>
          <validation-provider
            name="colorCode"
            :rules="rules['colorCode']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="colorCode"
              v-bind="inputOptions"
              :error-messages="errors"
              :maxlength="rules['colorCode'].max.length"
              :counter="rules['colorCode'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Link til video</label>
          <validation-provider
            name="videoUrl"
            :rules="rules['videoUrl']"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.trim="videoUrl"
              v-bind="inputOptions"
              :error-messages="errors"
              :maxlength="rules['videoUrl'].max.length"
              :counter="rules['videoUrl'].max.length"
            ></v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" class="py-0">
          <label>Mulig å bestille som Tester-versjon</label>
          <validation-provider
            name="hasTester"
            :rules="rules['hasTester']"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="hasTester"
              row
              class="mt-0"
              :error-messages="errors"
            >
              <v-radio
                label="Ja"
                :value="true"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
              <v-radio
                label="Nei"
                :value="false"
                on-icon="mdi-check-circle-outline"
              ></v-radio>
            </v-radio-group>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <label>Kampanje fra dato:</label>
          <validation-provider
            name="campaignFromDate"
            :rules="rules['campaignFromDate']"
            v-slot="{ errors }"
          >
            <date-picker
              v-model="campaignFromDate"
              :textFieldOptions="inputOptions"
              :error-messages="errors"
              clearable
            ></date-picker>
          </validation-provider>
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <label>Kampanje til dato:</label>
          <validation-provider
            name="campaignToDate"
            :rules="rules['campaignToDate']"
            v-slot="{ errors }"
          >
            <date-picker
              v-model="campaignToDate"
              :textFieldOptions="inputOptions"
              :error-messages="errors"
              clearable
            ></date-picker>
          </validation-provider>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
const powderBlue = "#DFF2F4";
import Vue from "vue";
import ProductImage from "../read/ProductImage.vue";
import ProductForm from "@/models/ProductForm";
import TextFieldCurrency from "./TextFieldCurrency.vue";
import rules from "@/views/products/components/edit/validation/rules";
import TextFieldDecimal from "./TextFieldDecimal.vue";
import TextFieldBarcode from "./TextFieldBarcode.vue";
import DatePicker from "@/components/DatePicker.vue";

const generateForm = () => {
  const productForm = new ProductForm();
  const generatedForm = Object.entries(productForm).reduce((obj, entry) => {
    const [key] = entry;
    obj[key] = {
      get() {
        return this.$store.state["product-form"].productForm[key];
      },
      set(value) {
        this.$store.commit("product-form/set", { key, value });
      },
    };
    return obj;
  }, {});
  return generatedForm;
};

export default {
  name: "EditProduct",
  components: {
    ProductImage,
    TextFieldCurrency,
    TextFieldDecimal,
    TextFieldBarcode,
    DatePicker,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    validationObserver: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      deletingBarcode: false,
      savingBarcode: false,
      deleteBarcodeDialog: false,
      powderBlue: powderBlue,
      inputOptions: {
        dense: true,
        filled: true,
        color: "primary",
        backgroundColor: powderBlue,
      },
      loadingBrands: false,
      loadingSeriesForBrand: {},
      rules,
    };
  },
  computed: {
    // Form
    ...generateForm(), // creates a writable computed for each form field

    // Server error messages
    dataProviderProductIdServerErrors() {
      return this.$store.state["product-form"].serverErrors
        .dataProviderProductId;
    },
    dataProviderProductIdServerValidationPending() {
      return this.$store.state["product-form"].serverValidationPending
        .dataProviderProductId;
    },

    supplementaryNumberServerErrors() {
      return this.$store.state["product-form"].serverErrors.supplementaryNumber;
    },
    supplementaryNumberServerValidationPending() {
      return this.$store.state["product-form"].serverValidationPending
        .supplementaryNumber;
    },

    // select items
    brands() {
      return this.$store.getters["brands/getBrands"];
    },

    seriesInBrand() {
      return this.$store.getters["series/getSeriesByBrandId"](this.brandId);
    },

    categories() {
      return this.$store.getters["categories/getCategories"];
    },

    hasFetchedBrands() {
      return this.$store.state.brands.allFetched;
    },
    hasFetchedCategories() {
      return this.$store.state.categories.hasFetchedCategories;
    },
    validation() {
      return this.$store.state["product-form"].validationObserver;
    },
  },
  watch: {
    product(newProduct) {
      this.setFormFromProduct(newProduct);
    },
    validationObserver(observer) {
      this.setValidationObserver(observer);
    },
    brandId(newValue, oldValue) {
      if (oldValue != null && newValue !== oldValue) {
        this.unsetSelectedProductSeriesId();
      }
      this.fetchSeriesInBrand(newValue);
    },
  },
  methods: {
    setValidationObserver(validationObserver) {
      this.$store.commit(
        "product-form/setValidationObserver",
        validationObserver
      );
    },
    setFormFromProduct(product) {
      this.$store.commit("product-form/setFromProduct", product);
    },
    unsetSelectedProductSeriesId() {
      this.productSeriesId = null;
    },
    setImage(base64img) {
      this.imageUrl = base64img;
    },
    async fetchBrands() {
      if (!this.loadingBrands && !this.hasFetchedBrands) {
        this.loadingBrands = true;
        await this.$store.dispatch("brands/fetchBrands");
        this.loadingBrands = false;
      }
    },
    async fetchSeriesInBrand(brandId) {
      if (brandId) {
        const hasFetched =
          this.$store.getters["series/hasFetchedSeriesForBrand"](brandId);
        if (!hasFetched && !this.loadingSeriesForBrand[brandId]) {
          Vue.set(this.loadingSeriesForBrand, brandId, true);
          await this.$store.dispatch("series/fetchSeries", brandId);
          Vue.set(this.loadingSeriesForBrand, brandId, false);
        }
      }
    },
    async fetchCategories() {
      if (!this.hasFetchedCategories) {
        this.$store.dispatch("categories/fetchCategories");
      }
    },
    async deleteBarcode(barcode) {
      if (barcode && this.product?.id > 0) {
        this.deletingBarcode = true;
        try {
          await this.$store.dispatch("product-form/deleteBarcode", {
            barcode,
            productId: this.product.id,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.deletingBarcode = false;
        }
        this.deleteBarcodeDialog = false;
      }
    },

    async addBarcode(barcode) {
      if (barcode && this.product?.id > 0) {
        try {
          this.savingBarcode = true;
          await this.$store.dispatch("product-form/addBarcode", {
            barcode,
            productId: this.product.id,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.savingBarcode = true;
        }
      }
    },
    validateDataProviderProductId(dataProviderProductId) {
      this.$store.dispatch(
        "product-form/validateDataProviderProductId",
        dataProviderProductId
      );
    },
    validateSupplementaryNumber(supplementaryNumber) {
      this.$store.dispatch(
        "product-form/validateSupplementaryNumber",
        supplementaryNumber
      );
    },
    resetValidation() {
      this.$store.commit("product-form/resetServerErrors");
    },
  },
  created() {
    this.resetValidation();
    this.fetchBrands();
    this.fetchSeriesInBrand(this.brandId);
    this.fetchCategories();
    this.setFormFromProduct(this.product);
  },
};
</script>

<style  scoped>
label {
  color: var(--v-primary-base);
  font-weight: bold;
  font-size: 12px;
}
</style>