<template>
  <div class="product-details-section mb-8">
    <h3 class="mb-2">Detaljer</h3>
    <table class="product-details-table">
      <tr v-for="(item, i) in productDetails" :key="i">
        <th class="pb-2 pr-2">{{ item.text }}</th>
        <td
          class="pb-2"
          :class="{ 'word-break-all': $vuetify.breakpoint.mobile }"
        >
          {{ item.value }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ProductDetailsSection",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAvailable() {
      return false;
    },
    productDetails() {
      return [
        {
          text: "Tilgjengelighet:",
          value: this.product.activeStatus ? "Aktiv" : "Inaktiv",
        },
        {
          text: "Navn i kassen:",
          value: this.product.productShortName,
        },
        {
          text: "Kategori:",
          value: this.product.productCategoryName,
        },

        {
          text: "Strekkoder:",
          value: (this.product.barcodes || []).join(),
        },
        {
          text: "Mva sats:",
          value: this.product.vat ? `${this.product.vat}%` : "",
        },
        {
          text: "Størelse og enhet",
          value: `${this.product.size} ${this.product.unit}`,
        },

        {
          text: "Minimum ordreantall:",
          value: this.product.minimumOrderQuantity,
        },
        {
          text: "Enheter pr pakke:",
          value: this.product.packageSize,
        },
        {
          text: "Leverandørens artikkelnr:",
          value: this.product.supplementaryNumber,
        },
        {
          text: "Produkttype:",
          value: this.product.productType,
        },
        {
          text: "Hyllekanttekst:",
          value: this.product.shelfDescription,
        },
        {
          text: "Farge:",
          value: this.product.color,
        },
        {
          text: "Fargekode:",
          value: this.product.colorCode,
        },
        {
          text: "Link til video:",
          value: this.product.videoUrl,
        },
        {
          text: "Har tester-versjon:",
          value: this.product.hasTester ? "Ja" : "Nei",
        },
        {
          text: "Kampanjeperiode:",
          value: this.getCampaignPeriod(),
        },
      ];
    },
  },
  methods: {
    getCampaignPeriod() {
      if (
        this.product.campaignFromDate == null ||
        this.product.campaignToDate == null
      ) {
        return "";
      }

      const today = moment().startOf("day");
      const from = moment(this.product.campaignFromDate);
      const to = moment(this.product.campaignToDate);
      if (to.isBefore(today) && from.isSame(to, "day")) {
        return "";
      }
      return `${moment(this.product.campaignFromDate).format(
        "DD.MM.YYYY"
      )} - ${moment(this.product.campaignToDate).format("DD.MM.YYYY")}`;
    },
  },
};
</script>
<style scoped lang="scss">
.product-details-section {
  h3 {
    font-size: 20px;
    line-height: 1;
  }
  .product-details-table {
    td,
    th {
      font-size: 14px;
      vertical-align: top;
    }
  }
}
</style>