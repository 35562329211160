import { render, staticRenderFns } from "./ProductDetailsSection.vue?vue&type=template&id=754f2417&scoped=true&"
import script from "./ProductDetailsSection.vue?vue&type=script&lang=js&"
export * from "./ProductDetailsSection.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetailsSection.vue?vue&type=style&index=0&id=754f2417&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754f2417",
  null
  
)

export default component.exports