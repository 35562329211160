<template>
  <div class="rounded-pill mr-3 d-inline-block" @click="saveProduct()">
    <v-btn
      depressed
      color="primary"
      class="white rounded-pill"
      :disabled="!saveable"
    >
      <span class="mr-2">Lagre</span>
      <v-icon small color="white">mdi-check</v-icon></v-btn
    >
  </div>
</template>

<script>
import notification from "@/utils/notification";
export default {
  name: "ProductFormSaveBtn",

  computed: {
    isSaving() {
      return this.$store.state["product-form"].isSaving;
    },
    saveable() {
      return (
        this.$store.getters["product-form/isValid"] &&
        this.$store.getters["product-form/isDirty"]
      );
    },
    validationObserver() {
      return this.$store.state["product-form"].validationObserver;
    },
  },
  methods: {
    validateForm() {
      this.validationObserver && this.validationObserver.validate();
    },
    async saveProduct() {
      this.validateForm();
      if (this.saveable && !this.isSaving) {
        const savedProduct = await this.$store.dispatch("product-form/save");
        if (savedProduct) {
          notification.primary("Produktet ble lagret");
          this.$emit("product:saved");
        }
      }
    },
  },
};
</script>

<style>
</style>