<template>
  <v-dialog v-model="dialog" class="product-create" fullscreen persistent>
    <v-card>
      <v-toolbar flat class="toolbar">
        <v-icon color="primary" @click="close()" class="mr-4"
          >mdi-chevron-left</v-icon
        >
        <v-toolbar-title
          class="primary--text font-weight-bold font-size-normal"
        >
          Opprett produkt</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            depressed
            outlined
            color="primary"
            class="white-bg rounded-pill mr-3"
            @click="close()"
          >
            <span class="mr-2">Avbryt</span>
            <v-icon small color="primary">mdi-close</v-icon>
          </v-btn>
          <product-form-save-btn
            @product:saved="close()"
          ></product-form-save-btn>
        </v-card-actions>
      </v-toolbar>
      <div class="dialog-content">
        <v-container class="py-0">
          <product-form class="mt-8" :product="product"></product-form>
        </v-container>
      </div>
    </v-card>
    <v-overlay :value="isSaving" color="black">
      <progress-bouncing center></progress-bouncing> Lagrer produkt
    </v-overlay>
  </v-dialog>
</template>

<script>
import ProductForm from "./components/edit/ProductForm.vue";
import ProductFormSaveBtn from "./components/edit/ProductFormSaveBtn.vue";
export default {
  name: "ProductUpdate",
  components: { ProductForm, ProductFormSaveBtn },
  props: {
    brandId: {
      type: Number,
      required: false,
    },
    serieId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    product() {
      return {
        brandId: this.brandId,
        productSeriesId: this.serieId,
      };
    },
    isSaving() {
      return this.$store.state["product-form"].isSaving;
    },
  },
  methods: {
    close() {
      if (this.serieId) {
        this.$router.push({ name: "Serie", params: { id: this.serieId } });
      } else if (this.brandId) {
        this.$router.push({ name: "Brand", params: { id: this.brandId } });
      } else {
        this.$router.push({ name: "Brands" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: linear-gradient(90deg, #bfe5e8, #efddd1);
}
.dialog-content {
  padding-top: 64px;
}

.font-size-normal {
  font-size: $font-size-root;
}
.white-bg {
  background: white;
}
.container {
  max-width: 1170px;
}
</style>