<template>
  <h1 class="primary--text mb-5 d-flex align-center">
    <v-icon color="primary" class="mr-2" role="button" @click="toProductSerie()"
      >mdi-chevron-left</v-icon
    >
    {{ product.productName }}
  </h1>
</template>

<script>
export default {
  name: "ProductName",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toProductSerie() {
      this.$router.push({
        name: "Serie",
        params: { id: this.product.productSeriesId },
      });
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 30px;
  line-height: 1;
}
</style>