<template>
  <div>
    <label>Strekkode</label>
    <validation-provider
      name="ean"
      v-slot="{ errors, pending }"
      :rules="{ ...rules['ean'], uniqueEan: !multi }"
      ref="validationProvider"
      :mode="custom"
    >
      <v-text-field
        v-model.trim="ean"
        placeholder="Skriv inn strekkode"
        v-bind="$attrs"
        :append-icon="multi ? 'mdi-plus' : undefined"
        :error-messages="errors"
        :maxlength="rules.ean.max.length"
        :counter="rules.ean.max.length"
        :loading="pending"
        @click:append="addBarcode(barcode)"
      ></v-text-field>

      <v-chip
        class="primary--text mb-5 mr-1"
        :color="powderBlue"
        v-for="barcode in barcodes"
        :key="barcode"
        close
        close-icon="mdi-minus"
        @click:close="openDeleteBarcodeDialog(barcode)"
        >{{ barcode }}</v-chip
      >
    </validation-provider>
    <v-dialog v-model="deleteBarcodeDialog">
      <v-card max-width="100%" width="480">
        <v-toolbar color="primary" dark flat>Fjerne strekkode?</v-toolbar>
        <template v-if="loading">
          <v-card-text class="pt-3"
            ><progress-bouncing center class="py-3"></progress-bouncing
          ></v-card-text>
        </template>
        <template v-else>
          <v-card-text class="pt-3"
            >Vil du fjerne strekkoden <b>{{ selectedBarcode }}</b> fra
            produktet?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              depressed
              rounded
              @click="deleteBarcodeDialog = false"
              >Avbryt</v-btn
            >
            <v-btn
              color="primary"
              depressed
              rounded
              @click="$emit('barcode:delete', selectedBarcode)"
              >Fjern</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from "@/views/products/components/edit/validation/rules";
const powderBlue = "#BFE5E8";
export default {
  name: "TextFieldBarcode",
  model: {
    prop: "barcode",
    event: "change",
  },
  props: {
    barcode: {
      type: String,
      required: true,
      default: "",
    },
    barcodes: {
      type: Array,
      required: false,
      default: () => [],
    },
    multi: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rules,
      powderBlue,
      deleteBarcodeDialog: this.dialog,
      selectedBarcode: null,
    };
  },
  computed: {
    ean: {
      get() {
        return this.barcode;
      },
      set(barcode) {
        this.$emit("change", barcode);
      },
    },
  },
  watch: {
    dialog(value) {
      this.deleteBarcodeDialog = value;
    },
    deleteBarcodeDialog(value) {
      this.$emit("dialog:change", value);
    },
  },
  methods: {
    custom() {
      return { on: ["blur"] };
    },
    openDeleteBarcodeDialog(barcode) {
      this.selectedBarcode = barcode;
      this.deleteBarcodeDialog = true;
    },
    addBarcode(barcode) {
      if (barcode) {
        this.ean = "";
        this.$emit("barcode:add", barcode);
      }
    },
  },
};
</script>

<style>
</style>