<template>
  <v-card class="product-card" flat v-bind="$attrs">
    <image-with-image-uploader
      :adminMode="adminMode"
      :image="image"
      :imageId="imageId"
      :imageType="imageType"
      imageBorder
      :aspectRatio="aspectRatio"
      :editBtnVisible="editBtnVisible"
      :minWidth="minWidth"
      :minHeight="minHeight"
      @image:uploaded="onImageUploaded($event)"
    ></image-with-image-uploader>
  </v-card>
</template>

<script>
import ImageWithImageUploader from "@/components/ImageWithImageUploader.vue";
import { SupplierProduct } from "@/models/enums/ImageTypes";

const PRODUCT_IMAGE_MIN_WIDTH = 500;
const PRODUCT_IMAGE_MIN_HEIGHT = 500;

export default {
  name: "ProductImage",
  components: {
    ImageWithImageUploader,
  },
  props: {
    adminMode: {
      type: Boolean,
      required: false,
      default: false
    },
    src: {
      type: String,
      required: false,
    },
    imageId: {
      type: Number,
      required: true,
      default: 0,
    },
    editBtnVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageType: SupplierProduct,
      aspectRatio: PRODUCT_IMAGE_MIN_WIDTH / PRODUCT_IMAGE_MIN_HEIGHT,
      minWidth: PRODUCT_IMAGE_MIN_WIDTH,
      minHeight: PRODUCT_IMAGE_MIN_HEIGHT,
    };
  },
  computed: {
    image() {
      return {
        src: this.src,
      };
    },
  },
  methods: {
    onImageUploaded(response) {
      // If existing product - update image in store
      if (response && response.success) {
        if (this.imageId) {
          this.updateProductImage(response.url);
        }
        this.$emit("image:uploaded", response.url);
      }
    },
    updateProductImage(imageUrl) {
      const product = this.$store.state.products.products[this.imageId];
      this.$store.commit("products/addOrUpdate", { ...product, imageUrl });
    },
  },
};
</script>