<template>
  <v-btn @click="$emit('click')" depressed color="primary" fab small outlined>
    <v-icon>mdi-pencil-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "EditProductBtn",
};
</script>

<style>
</style>