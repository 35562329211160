<template>
  <p>
    <router-link
      class="fixitGreen--text"
      :to="{ name: 'Brand', params: { id: product.brandId } }"
    >
      {{ product.brandName }}
    </router-link>
    /
    <router-link
      class="fixitGreen--text"
      :to="{ name: 'Serie', params: { id: product.productSeriesId } }"
    >
      {{ product.productSeriesName }}
    </router-link>
  </p>
</template>

<script>
export default {
  name: "BrandSerieName",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>