var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"edit-product pb-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('product-image',{attrs:{"src":_vm.imageUrl,"imageId":_vm.id,"max-width":"400"},on:{"image:uploaded":function($event){return _vm.setImage($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Produkt-ID")]),_c('validation-provider',{attrs:{"name":"dataProviderProductId","rules":_vm.rules['dataProviderProductId']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"disabled":_vm.id > 0,"error-messages":_vm.dataProviderProductIdServerErrors.concat(errors),"counter":_vm.rules['dataProviderProductId'].max.length,"maxlength":_vm.rules['dataProviderProductId'].max.length,"loading":_vm.dataProviderProductIdServerValidationPending},on:{"blur":function($event){return _vm.validateDataProviderProductId(_vm.dataProviderProductId)}},model:{value:(_vm.dataProviderProductId),callback:function ($$v) {_vm.dataProviderProductId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dataProviderProductId"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Merke")]),_c('validation-provider',{attrs:{"name":"brandId","rules":_vm.rules['brandId']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._b({attrs:{"items":_vm.brands,"item-value":"id","item-text":"name","error-messages":errors},model:{value:(_vm.brandId),callback:function ($$v) {_vm.brandId=$$v},expression:"brandId"}},'v-select',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Serie")]),_c('validation-provider',{attrs:{"name":"productSeriesId","rules":_vm.rules['productSeriesId']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._b({attrs:{"items":_vm.seriesInBrand,"item-value":"id","item-text":"name","error-messages":errors},model:{value:(_vm.productSeriesId),callback:function ($$v) {_vm.productSeriesId=$$v},expression:"productSeriesId"}},'v-select',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Produktnavn")]),_c('validation-provider',{attrs:{"name":"productName","rules":_vm.rules['productName']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"counter":_vm.rules['productName'].max.length,"maxlength":_vm.rules['productName'].max.length},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"productName"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Kort navn i kassen (maks 30 tegn)")]),_c('validation-provider',{attrs:{"name":"productShortName","rules":_vm.rules['productShortName']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"counter":_vm.rules['productShortName'].max.length,"maxlength":_vm.rules['productShortName'].max.length},model:{value:(_vm.productShortName),callback:function ($$v) {_vm.productShortName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"productShortName"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Beskrivelse")]),_c('validation-provider',{attrs:{"name":"description","rules":_vm.rules['description']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',_vm._b({attrs:{"error-messages":errors,"counter":_vm.rules['description'].max.length,"maxlength":_vm.rules['description'].max.length},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}},'v-textarea',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Type vare")]),_c('validation-provider',{attrs:{"name":"isSalesProduct","rules":_vm.rules['isSalesProduct']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","error-messages":errors},model:{value:(_vm.isSalesProduct),callback:function ($$v) {_vm.isSalesProduct=$$v},expression:"isSalesProduct"}},[_c('v-radio',{attrs:{"label":"Salgsvare","value":true,"on-icon":"mdi-check-circle-outline"}}),_c('v-radio',{attrs:{"label":"Forbruksvare","value":false,"on-icon":"mdi-check-circle-outline"}})],1)]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Veil. innpris (ekskl. mva)")]),_c('validation-provider',{attrs:{"name":"inPriceExVat","rules":_vm.rules['inPriceExVat']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-currency',_vm._b({attrs:{"error-messages":errors},model:{value:(_vm.inPriceExVat),callback:function ($$v) {_vm.inPriceExVat=$$v},expression:"inPriceExVat"}},'text-field-currency',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Anbefalt utpris (inkl. mva)")]),_c('validation-provider',{attrs:{"name":"recommendedSalesPriceIncVat","rules":_vm.rules['recommendedSalesPriceIncVat']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-currency',_vm._b({attrs:{"error-messages":errors},model:{value:(_vm.recommendedSalesPriceIncVat),callback:function ($$v) {_vm.recommendedSalesPriceIncVat=$$v},expression:"recommendedSalesPriceIncVat"}},'text-field-currency',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Fra hvilken dato den anbefalte prisen bør brukes")]),_c('validation-provider',{attrs:{"name":"priceChangeFromDate","rules":_vm.rules['priceChangeFromDate']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"textFieldOptions":_vm.inputOptions,"error-messages":errors,"clearable":""},model:{value:(_vm.priceChangeFromDate),callback:function ($$v) {_vm.priceChangeFromDate=$$v},expression:"priceChangeFromDate"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Tilgjengelighet")]),_c('validation-provider',{attrs:{"name":"activeStatus","rules":_vm.rules['activeStatus']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","error-messages":errors},model:{value:(_vm.activeStatus),callback:function ($$v) {_vm.activeStatus=$$v},expression:"activeStatus"}},[_c('v-radio',{attrs:{"label":"Aktiv","value":true,"on-icon":"mdi-check-circle-outline"}}),_c('v-radio',{attrs:{"label":"Inaktiv","value":false,"on-icon":"mdi-check-circle-outline"}})],1)]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('text-field-barcode',_vm._b({attrs:{"barcodes":_vm.barcodes,"loading":_vm.deletingBarcode || _vm.savingBarcode,"readonly":_vm.savingBarcode,"dialog":_vm.deleteBarcodeDialog,"multi":_vm.id > 0},on:{"dialog:change":function($event){_vm.deleteBarcodeDialog = $event},"barcode:delete":function($event){return _vm.deleteBarcode($event)},"barcode:add":function($event){return _vm.addBarcode($event)}},model:{value:(_vm.ean),callback:function ($$v) {_vm.ean=$$v},expression:"ean"}},'text-field-barcode',_vm.inputOptions,false))],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Mva-sats (%)")]),_c('validation-provider',{attrs:{"name":"vat","rules":_vm.rules['vat']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-decimal',_vm._b({attrs:{"error-messages":errors,"suffix":"%"},model:{value:(_vm.vat),callback:function ($$v) {_vm.vat=$$v},expression:"vat"}},'text-field-decimal',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Kategori")]),_c('validation-provider',{attrs:{"name":"productCategoryId","rules":_vm.rules['productCategoryId']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._b({attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.productCategoryId),callback:function ($$v) {_vm.productCategoryId=$$v},expression:"productCategoryId"}},'v-select',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3"}},[_c('label',[_vm._v("Størrelse")]),_c('validation-provider',{attrs:{"name":"size","rules":_vm.rules['size']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-decimal',_vm._b({attrs:{"error-messages":errors},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}},'text-field-decimal',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3"}},[_c('label',[_vm._v("Enhet")]),_c('validation-provider',{attrs:{"name":"unit","rules":_vm.rules['unit']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"unit"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3"}},[_c('label',[_vm._v("Enheter per pakke")]),_c('validation-provider',{attrs:{"name":"packageSize","rules":_vm.rules['packageSize']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"type":"number","min":_vm.rules['packageSize'].min_value.min,"error-messages":errors},model:{value:(_vm.packageSize),callback:function ($$v) {_vm.packageSize=$$v},expression:"packageSize"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"3"}},[_c('label',[_vm._v("Minimum ordreantall")]),_c('validation-provider',{attrs:{"name":"minimumOrderQuantity","rules":_vm.rules['minimumOrderQuantity']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"type":"number","min":_vm.rules['minimumOrderQuantity'].min_value.min,"error-messages":errors},model:{value:(_vm.minimumOrderQuantity),callback:function ($$v) {_vm.minimumOrderQuantity=$$v},expression:"minimumOrderQuantity"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Leverandørens artikkelnummer")]),_c('validation-provider',{attrs:{"name":"supplementaryNumber","rules":_vm.rules['supplementaryNumber']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":_vm.supplementaryNumberServerErrors.concat(errors),"maxlength":_vm.rules['supplementaryNumber'].max.length,"counter":_vm.rules['supplementaryNumber'].max.length,"loading":_vm.supplementaryNumberServerValidationPending},on:{"blur":function($event){return _vm.validateSupplementaryNumber(_vm.supplementaryNumber)}},model:{value:(_vm.supplementaryNumber),callback:function ($$v) {_vm.supplementaryNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"supplementaryNumber"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Hyllekanttekst")]),_c('validation-provider',{attrs:{"name":"shelfDescription","rules":_vm.rules['shelfDescription']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"maxlength":_vm.rules['shelfDescription'].max.length,"counter":_vm.rules['shelfDescription'].max.length},model:{value:(_vm.shelfDescription),callback:function ($$v) {_vm.shelfDescription=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"shelfDescription"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Produkttype")]),_c('validation-provider',{attrs:{"name":"productType","rules":_vm.rules['productType']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"maxlength":_vm.rules['productType'].max.length,"counter":_vm.rules['productType'].max.length},model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"productType"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Farge")]),_c('validation-provider',{attrs:{"name":"color","rules":_vm.rules['color']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"maxlength":_vm.rules['color'].max.length,"counter":_vm.rules['color'].max.length},model:{value:(_vm.color),callback:function ($$v) {_vm.color=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"color"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Fargekode")]),_c('validation-provider',{attrs:{"name":"colorCode","rules":_vm.rules['colorCode']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"maxlength":_vm.rules['colorCode'].max.length,"counter":_vm.rules['colorCode'].max.length},model:{value:(_vm.colorCode),callback:function ($$v) {_vm.colorCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"colorCode"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Link til video")]),_c('validation-provider',{attrs:{"name":"videoUrl","rules":_vm.rules['videoUrl']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({attrs:{"error-messages":errors,"maxlength":_vm.rules['videoUrl'].max.length,"counter":_vm.rules['videoUrl'].max.length},model:{value:(_vm.videoUrl),callback:function ($$v) {_vm.videoUrl=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"videoUrl"}},'v-text-field',_vm.inputOptions,false))]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("Mulig å bestille som Tester-versjon")]),_c('validation-provider',{attrs:{"name":"hasTester","rules":_vm.rules['hasTester']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","error-messages":errors},model:{value:(_vm.hasTester),callback:function ($$v) {_vm.hasTester=$$v},expression:"hasTester"}},[_c('v-radio',{attrs:{"label":"Ja","value":true,"on-icon":"mdi-check-circle-outline"}}),_c('v-radio',{attrs:{"label":"Nei","value":false,"on-icon":"mdi-check-circle-outline"}})],1)]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Kampanje fra dato:")]),_c('validation-provider',{attrs:{"name":"campaignFromDate","rules":_vm.rules['campaignFromDate']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"textFieldOptions":_vm.inputOptions,"error-messages":errors,"clearable":""},model:{value:(_vm.campaignFromDate),callback:function ($$v) {_vm.campaignFromDate=$$v},expression:"campaignFromDate"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Kampanje til dato:")]),_c('validation-provider',{attrs:{"name":"campaignToDate","rules":_vm.rules['campaignToDate']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"textFieldOptions":_vm.inputOptions,"error-messages":errors,"clearable":""},model:{value:(_vm.campaignToDate),callback:function ($$v) {_vm.campaignToDate=$$v},expression:"campaignToDate"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }