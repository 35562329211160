<template>
  <div class="mb-8">
    <div class="d-flex mb-5">
      <!-- Prices -->
      <div class="d-flex align-start font-weight-bold mr-8">
        <span class="price-text mr-2">Veil. innpris ekskl. mva</span>
        <span class="price-value">{{ product.inPriceExVat | currency }}</span>
      </div>
      <div class="d-flex align-start font-weight-bold mr-8">
        <span class="price-text mr-2">Anbefalt utpris inkl. mva</span>
        <span class="price-value">
          {{ product.recommendedSalesPriceIncVat | currency }}</span
        >
      </div>
    </div>
    <!-- Derrived values -->
    <div class="price-text">
      <div class="mb-2">
        <b class="mr-2">Brutto fortjeneste:</b>
        <template v-if="grossProfit >= 0">{{
          grossProfit | currency
        }}</template>
        <template v-else>N/A</template>
      </div>
      <div><b class="mr-2">DG: </b>{{ contributionMarginFormatted }}</div>
    </div>
  </div>
</template>

<script>
import round from "lodash.round";
export default {
  name: "ProductPrices",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    vatDecimal() {
      return this.product.vat / 100;
    },
    recommendedSalesPriceExVat() {
      return this.product.recommendedSalesPriceIncVat / (1 + this.vatDecimal);
    },
    grossProfit() {
      return this.recommendedSalesPriceExVat - this.product.inPriceExVat;
    },
    contributionMarginPct() {
      if (!this.recommendedSalesPriceExVat) return null;
      return round(
        (this.grossProfit / this.recommendedSalesPriceExVat) * 100,
        1
      );
    },
    contributionMarginFormatted() {
      return this.contributionMarginPct == null
        ? "N/A"
        : `${this.contributionMarginPct.toLocaleString()}%`;
    },
  },
};
</script>

<style scoped>
.price-text {
  line-height: 1;
  font-size: 14px;
}

.price-value {
  line-height: 1;
  font-size: 24px;
}
</style>