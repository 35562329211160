<template>
  <v-text-field
    v-model="textInput"
    class="currency"
    v-bind="$attrs"
    @focus="onFocus"
    @blur="onBlur"
  ></v-text-field>
</template>

<script>
import round from "lodash.round";

function toNumber(value) {
  // Remove characters that are invalid for parseFloat (except comma (,))
  // Then replace Norwegian decimal separator with a decimal point (.)
  const defaultValue = 0;
  const str =
    value != null
      ? value
          .toString()
          .replace(/[^0-9.,-]+/g, "")
          .replaceAll(",", ".")
      : defaultValue;
  const parsedFloat = parseFloat(str);
  return !isNaN(parsedFloat) ? round(parsedFloat, 2) : defaultValue;
}

function toDisplayString(number) {
  return typeof number === "number"
    ? "kr " +
        number
          .toFixed(2)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    : "";
}

function toInputString(value) {
  const n = toNumber(value);
  return n.toFixed(2).replace(".", ",");
}

export default {
  name: "TextFieldCurrency",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Number],
    },
  },
  data() {
    return {
      textInput: toDisplayString(this.value),
    };
  },
  watch: {
    value(newValue) {
      this.textInput = toDisplayString(newValue);
    },
  },
  methods: {
    onFocus() {
      this.textInput = toInputString(this.textInput);
    },
    onBlur() {
      const number = toNumber(this.textInput);
      this.$emit("change", number);
      this.textInput = toDisplayString(number);
    },
  },
};
</script>

<style  scoped>
.currency >>> input {
  text-align: right;
}
</style>